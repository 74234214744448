
    import { defineComponent } from "vue";
    import vm from '@/main';
    import draggable from 'vuedraggable'
    import { courseModel} from "@/models/WorkspaceModel";
    import SecondNavbar from "@/components/SecondNavbar.vue";
    import WorkspaceCourseApi from "@/apis/WorkspaceCourseApi";

    export default defineComponent({
        name: "courseWorkspace",
        components: {
            SecondNavbar,
            draggable
        },
        data() {
            return {
                courseFolder: new Array<courseModel>(),
                freeCourse: new Array<courseModel>(),
                searchKeyword: "",
                stayIsFree: false,
                pages:{
                    pageNumber: 1, // 目前頁碼，從1開始
                    pageSize: 10, // 一頁幾筆，任意>0
                    totalPages: 3, // 總頁面數，從0開始
                },
                freeCourseIds: [] as any[],
            };
        },
        mounted() {

        },
        watch: {
            async stayIsFree(newVal) {
                this.$store.commit('updateLoading', true);
                // if(newVal == false){
                //     console.log("stayIsFree is changing")
                //     for(let j=0; j<this.freeCourse.length; j++){
                //         this.freeCourseIds.push(this.freeCourse[j].uuid)
                //         console.log("id: "+ this.freeCourse[j].uuid)
                //     }
                //     this.$store.commit('updateLoading', true);
                //     try{
                //         const upDateFreeCourse = JSON.parse(JSON.stringify(await WorkspaceCourseApi.updateFreeCourses(this.freeCourseIds)));
                //     }
                //     catch(e){
                //         console.log(e);
                //     }
                //     finally{
                //         this.$store.commit('updateLoading', false);
                //     }
                //     this.$store.commit('changeFreeCourse', this.freeCourse);
                // }
                try{
                    let courseQueryResult;
                    if(this.stayIsFree == false){
                        courseQueryResult = JSON.parse(JSON.stringify(await WorkspaceCourseApi.getCoursesByName("" ,this.pages.pageNumber)));
                        this.courseFolder = courseQueryResult.results.results;
                        this.pages.totalPages = courseQueryResult.results.totalPages;
                        this.pages.pageNumber = courseQueryResult.results.pageNumber;
                    }
                    else{
                        courseQueryResult = JSON.parse(JSON.stringify(await WorkspaceCourseApi.getFreeCourses()));
                        this.freeCourse = courseQueryResult.freeCourses;
                    }
                }
                catch(e){
                    console.log(e);
                }
                finally{
                    this.$store.commit('updateLoading', false);
                }
            },
        },
        methods: {
            deleteFreeCourse(index: number){
                this.$bvModal.msgBoxConfirm('确定要將此課程從免費課程移除吗?',{
                    title: 'Please Confirm',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: '确定',
                    cancelTitle: '取消',
                    footerClass: 'p-2',
                    hideHeaderClose: true,
                    centered: true
                })
                .then(async value => {
                    if(value){
                        if (index !== -1) {
                            this.freeCourse.splice(index, 1);
                        }
                        this.freeCourseIds = []
                        this.$store.commit('changeFreeCourse', this.freeCourse);
                        for(let j=0; j<this.freeCourse.length; j++){
                            this.freeCourseIds.push(this.freeCourse[j].uuid)
                        }
                        this.$store.commit('updateLoading', true);
                        try{
                            const upDateFreeCourse = JSON.parse(JSON.stringify(await WorkspaceCourseApi.updateFreeCourses(this.freeCourseIds)));
                        }
                        catch(e){
                            console.log(e);
                        }
                        finally{
                            this.$store.commit('updateLoading', false);
                        }
                    }
                })
                .catch(err => {
                // An error occurred
                })
            },
            editFreeCourse(){
                this.$store.commit('changeFreeCourse', this.freeCourse);
                this.$router.push({
                    name: "EditFreeCourse",
                });
            },
            clickIsFree(isFree: boolean){
                this.stayIsFree = isFree;
            },
            async onPageChange(newPage:number){
                console.log("Debug: onPageChange newPage= ", newPage);
                console.log("Debug: onPageChange buttonSearch keyword= ", this.searchKeyword);
                if(newPage < 1)  newPage = 1;
                else if(newPage > this.pages.totalPages)    newPage = this.pages.totalPages
                this.$store.commit('updateLoading', true);
                try{
                    const courseQueryResult = JSON.parse(JSON.stringify(await WorkspaceCourseApi.getCoursesByName(this.searchKeyword ,newPage)));
                    this.courseFolder = courseQueryResult.results.results;
                    this.pages.totalPages = courseQueryResult.results.totalPages;
                }
                catch(e){
                    console.log(e);
                }
                finally{
                    this.$store.commit('updateLoading', false);
                }
            },
            clearKeyword(){
                console.log("Debug: clearKeyword");
                this.searchKeyword = "";
                this.searchByname(this.searchKeyword);
            },
            async searchByname(keyword:string){
                console.log("Debug: searchByCoursename keyword= ", keyword);
                this.searchKeyword = keyword;
                this.$store.commit('updateLoading', true);
                try{
                    const courseQueryResult = JSON.parse(JSON.stringify(await WorkspaceCourseApi.getCoursesByName(keyword ,1)));
                    this.courseFolder = courseQueryResult.results.results;
                    this.pages.totalPages = courseQueryResult.results.totalPages;
                    this.pages.pageNumber = courseQueryResult.results.pageNumber;
                }
                catch(e){
                    console.log(e);
                }
                finally{
                    this.$store.commit('updateLoading', false);
                }
            },
            editCourse(index:number){
                this.$store.commit('changeCourseWorkspace', this.courseFolder[index]);
                this.$router.push({name: "EditCourse"});
            },
            async deleteCourse(index:number){
                const courseName = this.courseFolder[index].metadata.name;
                this.$bvModal.msgBoxConfirm(`确定要刪除「${courseName}」吗?`,{
                    title: 'Please Confirm',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: '确定',
                    cancelTitle: '取消',
                    footerClass: 'p-2',
                    hideHeaderClose: true,
                    centered: true
                })
                .then(async value => {
                if(value) {
                    const userConfirmation = prompt("确认要刪除吗？（输入“确认刪除”进行刪除）");
                    if(userConfirmation == null){
                        return false;
                    }
                    else if (userConfirmation !== "确认刪除") {
                        alert("輸入錯誤")
                        this.deleteCourse(index);
                        return false;
                    }
                    else{
                        try{
                            await WorkspaceCourseApi.deleteCourse(this.courseFolder[index].uuid);
                            this.courseFolder.splice(index, 1);
                            alert("刪除成功")
                        }
                        catch(e){
                            console.log(e);
                        }
                        finally{

                            this.$store.commit('updateLoading', false);
                        }
                    }
                }
                })
                .catch(err => {
                // An error occurred
                })
            },
            // async saveFreeCourseOrder(){
            //     for(let j=0; j<this.freeCourse.length; j++){
            //         this.freeCourseIds.push(this.freeCourse[j].uuid)
            //     }
            //     this.$store.commit('updateLoading', true);
            //     try{
            //         const upDateFreeCourse = JSON.parse(JSON.stringify(await WorkspaceCourseApi.updateFreeCourses(this.freeCourseIds)));
            //     }
            //     catch(e){
            //         console.log(e);
            //     }
            //     finally{
            //         this.$store.commit('updateLoading', false);
            //     }
            //     this.$store.commit('changeFreeCourse', this.freeCourse);
            // }
        },
        // async beforeDestroy() {
        //     console.log('befoore destroy');
        //     for(let j=0; j<this.freeCourse.length; j++){
        //         this.freeCourseIds.push(this.freeCourse[j].uuid)
        //     }
        //     this.$store.commit('updateLoading', true);
        //     try{
        //         const upDateFreeCourse = JSON.parse(JSON.stringify(await WorkspaceCourseApi.updateFreeCourses(this.freeCourseIds)));
        //     }
        //     catch(e){
        //         console.log(e);
        //     }
        //     finally{
        //         this.$store.commit('updateLoading', false);
        //     }
        //     this.$store.commit('changeFreeCourse', this.freeCourse);
        // },
        async created(){
            if(this.$router.currentRoute.path.includes('free-course')){
                console.log("Free Course")
                this.stayIsFree = true;
                this.$store.commit('updateLoading', true);
                try{
                    let courseQueryResult;
                    courseQueryResult = JSON.parse(JSON.stringify(await WorkspaceCourseApi.getFreeCourses()));
                    this.freeCourse = courseQueryResult.freeCourses;
                }
                catch(e){
                    console.log(e);
                }
                finally{
                    this.$store.commit('updateLoading', false);
                }
            }
            else{
                console.log("Course")
                this.stayIsFree = false;
                this.$store.commit('updateLoading', true);
                try{
                    const courseQueryResult = JSON.parse(JSON.stringify(await WorkspaceCourseApi.getCoursesByName("" ,this.pages.pageNumber)));
                    this.courseFolder = courseQueryResult.results.results;
                    this.pages.totalPages = courseQueryResult.results.totalPages;
                    this.pages.pageNumber = courseQueryResult.results.pageNumber;
                }
                catch(e){
                    console.log(e);
                }
                finally{
                    this.$store.commit('updateLoading', false);
                }
            }

        }
    });
